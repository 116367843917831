import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ReceiptIcon from '@mui/icons-material/Receipt';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import TextField from "@mui/material/TextField";
import { useNavigate } from 'react-router-dom';
import { createOrderFromCart, setOrderNote } from '../../redux/slices/orderSlice';
import { toast } from 'react-toastify';
import CouponModal from './CouponModal';

export default function CartCheckout() {
  const localCart = useSelector(state => state.cart.localCart);
  const serverCart = useSelector(state => state.cart.serverCart);
  const { isAuthenticated } = useSelector(state => state.auth);
  const cart = isAuthenticated ? serverCart : localCart;
  const { orderNote, selectedAddress } = useSelector(state => state.order);

  const dispatch = useDispatch();
  const theme = useTheme();
  const [note, setNote] = useState(orderNote || "");
  const [openModal, setOpenModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);


  const freeShippingThreshold = 200;
  const progressPercentage = Math.min((cart.total / freeShippingThreshold) * 100, 100);
  const amountRemaining = Math.max(freeShippingThreshold - cart.total, 0);
  const deliveryCharge = 30;
  

  const calculateGrandTotal = () => {
    if (amountRemaining === 0) {
      return cart.total ;
    }
    return cart.total + deliveryCharge;
  };

  const navigate = useNavigate();

  const handlePlaceOrder = () => {
    if (!selectedAddress) {
      toast.warn("Please select an address before placing an order", {
        position: "top-center"
      });
    } else {
      const addressId = selectedAddress.id;
      dispatch(createOrderFromCart({ addressId, orderNote, selectedCoupon, deliveryCharge }));
      navigate("/checkout");
    }
  };

  useEffect(() => {
    if (note) {
      dispatch(setOrderNote(note));
    }
  }, [note, dispatch]);

  return (
    <Box>


      <Box sx={{ display: "flex", flexDirection: "column" }}>


        <Box sx={{ my: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant='body2' gutterBottom sx={{ fontWeight: 800, textTransform: "upper" }}>   {selectedCoupon ? `Coupon Code: ${selectedCoupon}` : "Apply Coupon"}</Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpenModal(true)}
          >
            {selectedCoupon ? "Change" : "Add"}
          </Button>
        </Box>

        <Divider flexItem />


        <Typography variant='caption' gutterBottom sx={{ fontWeight: 700, textTransform: "uppercase", mt: 2 }}>
          Free shipping for any order above <span style={{ color: theme.palette.primary.main }}> ₹ {freeShippingThreshold.toFixed(2)}</span>
        </Typography>

        <Typography gutterBottom variant='caption' sx={{ fontWeight: 700, color: "gray", textTransform: "uppercase" }}>
          Spend <span style={{ color: theme.palette.primary.main }}>₹ {amountRemaining.toFixed(2)}</span> for free shipping
        </Typography>

        <Box sx={{ position: 'relative', width: '100%', my: 3 }}>
          <LinearProgress variant="determinate" value={progressPercentage} sx={{ height: 20, borderRadius: 5 }} />
          <Box sx={{ position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontWeight: 'bold', fontSize: "0.8rem" }}>
            {progressPercentage >= 10 && <span>{progressPercentage.toFixed(0)}%</span>}
          </Box>
        </Box>
      </Box>

      <Divider flexItem />

      <Box sx={{ py: 2 }}>
        <Typography variant='body2' gutterBottom sx={{ fontWeight: 800, textTransform: "upper" }}>Bill details</Typography>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <ReceiptIcon sx={{ fontSize: "1rem" }} />
            <Typography variant='body2'>Sub Total</Typography>
          </Box>
          <Typography variant='body2'>₹ {cart.total}</Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <DeliveryDiningIcon sx={{ fontSize: "1rem" }} />
            <Typography variant='body2'>Delivery Charge</Typography>
          </Box>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography variant='body2' sx={{ textDecoration: amountRemaining === 0 ? "line-through" : "none" }}>₹ {deliveryCharge}</Typography>
            {amountRemaining === 0 && <Typography variant='body2' color="primary">FREE</Typography>}
          </Box>
        </Box>


        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant='subtitle2'>Grand Total</Typography>
          <Typography variant='subtitle2'>₹ {calculateGrandTotal()}</Typography>
        </Box>
      </Box>


      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold' }}>
          Delivery Address
        </Typography>

        {selectedAddress ? (
          <Box>
            <Typography variant='body2'>{selectedAddress.user_name}, {selectedAddress.pincode}</Typography>
            <Typography variant='body2'>{selectedAddress.building_name_area}, {selectedAddress.city}, {selectedAddress.state}, {selectedAddress.country}</Typography>
            <Typography variant='body2'>Mobile: <strong>{selectedAddress.phone_number}</strong></Typography>
          </Box>
        ) : (
          <Typography variant='body2'>No address selected.</Typography>
        )}
      </Box>



      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold' }}>
          Add a note to your order:
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Add your note here..."
        />
      </Box>




      <CouponModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        onSelectCoupon={(coupon) => setSelectedCoupon(coupon)}
      />

      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePlaceOrder}
          fullWidth
          sx={{ color: "white" }}
        >
          Place Order
        </Button>
      </Box>
    </Box>
  );
}
