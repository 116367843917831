import React from 'react'
import Box from "@mui/material/Box"

const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;

const bgIMG = `${baseCloudinaryURL}/v1726942089/Copy_of_Order_now_cpbieg.png`
export default function FarmToHomeBanner() {
    
  return (
    <Box sx={{height:"50vh",height:"100%", width:"100%",mt:10}}>
        <img src={bgIMG} style={{width:"100%",height:"100%",objectFit:"cover", maxHeight:"60vh"}}/>
    </Box>
  )
}
