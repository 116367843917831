import React from 'react'
import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from '@mui/icons-material/Delete';
import QuantitySelector from './QuantitySelector'
import { useDispatch, useSelector } from 'react-redux'
import { removeItemFromCart, removeItemFromLocalCart, removeItemFromServerCart } from '../../redux/slices/cartSlice'
import Box from "@mui/material/Box"


export default function CartItem({ item }) {
  console.log(item)
  const dispatch = useDispatch();

  const {isAuthenticated} = useSelector(state=>state.auth);
  
  const handleRemove = () => {
    if (isAuthenticated) {
      dispatch(removeItemFromServerCart({ productId: item.product.id }));
    } else {
      dispatch(removeItemFromLocalCart({ productId: item.product.id }));
    }
  };




  return (
    <Card sx={{ display: 'flex', flexDirection: { xs: "column", sm: "row" }, alignItems: { xs: "flex-start", sm: "center" }, mb: 1, boxShadow: 1, p: 1, width: "100%", textAlign: "left", justifyContent: { xs: "left", sm: "space-between" }, gap: 1 }}>
      <Box sx={{
        display: "flex", alignItems: "center",
        flexDirection: { xs: "row-reverse", sm: "row" }, gap: { xs: 5, sm: 1 }
      }}>
        <IconButton onClick={handleRemove} aria-label="delete">
          <DeleteIcon fontSize='small' />
        </IconButton>
        <CardMedia
          component="img"
          image={item.product.image}
          alt={item.product.name}
          sx={{ width: { xs: 300, sm: 64, }, height: { xs: 100, sm: 64 }, borderRadius: 2 }}
        />

      </Box>


      <Typography sx={{ fontWeight: 500, flex: 3, textAlign: "center" }} variant="body1">{item.product.name}</Typography>

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flex: 4, gap: 5 }}>

        <Typography sx={{ fontWeight: 500 }} color="primary" variant="body2" >₹ {item.product.discounted_price * 1}</Typography>
        <QuantitySelector item={item} />

        <Typography sx={{ fontWeight: 500 }} color="primary" variant="body2" >₹ {item.product.discounted_price * 1 * item.quantity}</Typography>

      </Box>

    </Card>
  )
}



