import React, { useState, useCallback } from 'react';
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { updateItemQuantityInLocalCart, updateItemQuantityInServerCart } from '../../redux/slices/cartSlice';

export default function QuantitySelector({ item }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [localQuantity, setLocalQuantity] = useState(item.quantity);

  // Check if the user is authenticated
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // Debounced function for updating item quantity
  const debouncedUpdateQuantity = useCallback(
    debounce((newQuantity) => {
      if (isAuthenticated) {
        // Dispatch the server cart update for logged-in users
        dispatch(updateItemQuantityInServerCart({ itemId: item.product.id, quantity: newQuantity, product_unit: item.product.product_unit }));
      } else {
        // Dispatch the local cart update for guests
        dispatch(updateItemQuantityInLocalCart({ itemId: item.product.id, quantity: newQuantity, product_unit: item.product.product_unit }));
      }
    }, 500),
    [dispatch, item.product.id, item.product.product_unit, isAuthenticated]
  );

  const handleIncreaseQuantity = () => {
    const newQuantity = localQuantity + 1;
    setLocalQuantity(newQuantity);
    debouncedUpdateQuantity(newQuantity);
  };

  const handleDecreaseQuantity = () => {
    if (localQuantity > 1) {
      const newQuantity = localQuantity - 1;
      setLocalQuantity(newQuantity);
      debouncedUpdateQuantity(newQuantity);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", border: "0.2px solid lightgray", borderRadius: "0.3rem" }}>
      <Button
        sx={{
          maxWidth: '30px', minWidth: '30px', minHeight: '30px',
          borderRight: "1px solid lightGray", borderTopRightRadius: "0px", borderBottomRightRadius: "0px", "&:hover": { bgcolor: theme.palette.primary.main, color: theme.palette.text.primary }
        }}
        onClick={handleDecreaseQuantity}
      >
        -
      </Button>
      <TextField
        type="number"
        color='primary'
        value={localQuantity}
        inputProps={{ min: 1, style: { textAlign: 'center', fontSize: "0.8rem" } }}
        size="small"
        sx={{ width: "50px", "& fieldset": { border: 'none' } }}
        disabled
      />
      <Button
        sx={{
          maxWidth: '30px', minWidth: '30px', minHeight: '30px',
          borderLeft: "1px solid lightGray", borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", "&:hover": { bgcolor: theme.palette.primary.main, color: theme.palette.text.primary }
        }}
        onClick={handleIncreaseQuantity}
      >
        +
      </Button>
    </Box>
  );
}
