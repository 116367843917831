import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import couponService from '../../services/cartService';
import CouponItem from './CouponItem';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
};

export default function CouponModal({ open, handleClose, onSelectCoupon }) {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await couponService.getAllCoupons();
        setCoupons(response.data);
      } catch (err) {
        console.error(err);
        setError('Failed to load coupons');
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchCoupons();
    }
  }, [open]);

  const handleCouponSelect = (coupon) => {
    setSelectedCoupon(coupon.id);
    onSelectCoupon(coupon.coupon_code);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" sx={{ px: 2, pt: 2 }}>
          Select a Coupon
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ height: "350px", overflowY: 'scroll', p: 2 }}>
          {loading ? (
            <Typography>Loading coupons...</Typography>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : coupons.length > 0 ? (
            coupons.map((coupon) => (
              <CouponItem 
                key={coupon.id} 
                coupon={coupon} 
                selectedCoupon={selectedCoupon} 
                onSelect={handleCouponSelect} 
              />
            ))
          ) : (
            <Typography>No coupons available.</Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
