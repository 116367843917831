import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia'
import Rating from '@mui/material/Rating';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from "@mui/material/Container"
import { useTheme } from "@mui/material/styles"
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import 'swiper/css/bundle';

const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;
const bgImageURL = `${baseCloudinaryURL}/v1726816942/Untitled_design_4_eya6o0.png`;
// const orangeImageURL = `${process.env.REACT_APP_CLOUDINARY_BASE_URL}/v1726823346/vegingo/orange-poster_mkxcmg.png`;
// const mangoImageURL = `${process.env.REACT_APP_CLOUDINARY_BASE_URL}/v1726824174/vegingo/mango-png-image_dptm6j.png`;

const QuoteOpening = () => {
  return (
    <svg fill="white" version="1.1" id="Layer_1" width="30px" height="30px" viewBox="0 0 70 70" enableBackground="new 0 0 70 70">
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <g>
          <path d="M27.792,8.103v9.427c-5.957,1.364-8.811,5.091-9.185,12.032h9.185v22.336H7.813V33.531 C7.813,20.257,13.521,10.083,27.792,8.103 M27.792,4.103c-0.183,0-0.366,0.013-0.55,0.038C12.134,6.237,3.813,16.675,3.813,33.531 v18.366c0,2.209,1.791,4,4,4h19.979c2.209,0,4-1.791,4-4V29.562c0-2.209-1.791-4-4-4h-4.411c0.988-2.571,2.832-3.567,5.304-4.133 c1.818-0.417,3.107-2.034,3.107-3.899V8.103c0-1.156-0.501-2.257-1.373-3.017C29.687,4.448,28.751,4.103,27.792,4.103L27.792,4.103 z" />
          <path d="M62.187,18.103v9.427c-5.955,1.364-8.809,5.091-9.183,12.032h9.183v22.336H42.208V43.531 C42.208,30.257,47.917,20.083,62.187,18.103 M62.187,14.103c-0.183,0-0.366,0.013-0.55,0.038 c-15.108,2.097-23.429,12.534-23.429,29.391v18.366c0,2.209,1.791,4,4,4h19.979c2.209,0,4-1.791,4-4V39.562c0-2.209-1.791-4-4-4 h-4.409c0.988-2.571,2.831-3.567,5.303-4.133c1.817-0.417,3.106-2.034,3.106-3.899v-9.427c0-1.156-0.501-2.257-1.373-3.017 C64.081,14.448,63.146,14.103,62.187,14.103L62.187,14.103z" />
          <g>
            <path d="M24.583,45.565c-0.553,0-1-0.447-1-1V33.583h-4.654c-0.553,0-1-0.447-1-1s0.447-1,1-1h6c0.553,0,0.654,0.43,0.654,0.982 v12C25.583,45.118,25.136,45.565,24.583,45.565z" />
          </g>
          <g>
            <path d="M59.583,57.565c-0.553,0-1-0.447-1-1v-2c0-0.553,0.447-1,1-1s1,0.447,1,1v2C60.583,57.118,60.136,57.565,59.583,57.565z" />
          </g>
          <g>
            <path d="M59.583,51.565c-0.553,0-1-0.447-1-1v-6.982h-4.654c-0.553,0-1-0.447-1-1s0.447-1,1-1h6c0.553,0,0.654,0.43,0.654,0.982v8 C60.583,51.118,60.136,51.565,59.583,51.565z" />
          </g>
        </g>
      </g>
    </svg>
  );
};


const QuoteClosing = () => {
  return (
    <svg fill="white" version="1.1" id="Layer_1" width="30px" height="30px" viewBox="0 0 70 70" enableBackground="new 0 0 70 70" transform="matrix(1, 0, 0, 1, 0, 0)rotate(180)">
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <g>
          <path d="M27.792,8.103v9.427c-5.957,1.364-8.811,5.091-9.185,12.032h9.185v22.336H7.813V33.531 C7.813,20.257,13.521,10.083,27.792,8.103 M27.792,4.103c-0.183,0-0.366,0.013-0.55,0.038C12.134,6.237,3.813,16.675,3.813,33.531 v18.366c0,2.209,1.791,4,4,4h19.979c2.209,0,4-1.791,4-4V29.562c0-2.209-1.791-4-4-4h-4.411c0.988-2.571,2.832-3.567,5.304-4.133 c1.818-0.417,3.107-2.034,3.107-3.899V8.103c0-1.156-0.501-2.257-1.373-3.017C29.687,4.448,28.751,4.103,27.792,4.103L27.792,4.103 z" />
          <path d="M62.187,18.103v9.427c-5.955,1.364-8.809,5.091-9.183,12.032h9.183v22.336H42.208V43.531 C42.208,30.257,47.917,20.083,62.187,18.103 M62.187,14.103c-0.183,0-0.366,0.013-0.55,0.038 c-15.108,2.097-23.429,12.534-23.429,29.391v18.366c0,2.209,1.791,4,4,4h19.979c2.209,0,4-1.791,4-4V39.562c0-2.209-1.791-4-4-4 h-4.409c0.988-2.571,2.831-3.567,5.303-4.133c1.817-0.417,3.106-2.034,3.106-3.899v-9.427c0-1.156-0.501-2.257-1.373-3.017 C64.081,14.448,63.146,14.103,62.187,14.103L62.187,14.103z" />
          <g>
            <path d="M24.583,45.565c-0.553,0-1-0.447-1-1V33.583h-4.654c-0.553,0-1-0.447-1-1s0.447-1,1-1h6c0.553,0,0.654,0.43,0.654,0.982 v12C25.583,45.118,25.136,45.565,24.583,45.565z" />
          </g>
          <g>
            <path d="M59.583,57.565c-0.553,0-1-0.447-1-1v-2c0-0.553,0.447-1,1-1s1,0.447,1,1v2C60.583,57.118,60.136,57.565,59.583,57.565z" />
          </g>
          <g>
            <path d="M59.583,51.565c-0.553,0-1-0.447-1-1v-6.982h-4.654c-0.553,0-1-0.447-1-1s0.447-1,1-1h6c0.553,0,0.654,0.43,0.654,0.982v8 C60.583,51.118,60.136,51.565,59.583,51.565z" />
          </g>
        </g>
      </g>
    </svg>
  );
};





const testimonials = [
  {
    text: "I absolutely love this product! It's made a huge difference in my life.",
    author: "Jane Doe",
    rating: 5,
    profileImage: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    text: "Amazing service! The team was very helpful and responsive.",
    author: "Jane Smith",
    rating: 4,
    profileImage: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    text: "This is the best app I've ever used. It's so intuitive and user-friendly.",
    author: "Woofer",
    rating: 4.5,
    profileImage: "https://images.unsplash.com/photo-1536164261511-3a17e671d380?q=80&w=1882&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  }
];

// const bounceAnimation = {
//   '@keyframes bounce': {
//     '0%, 100%': {
//       transform: 'translateY(0)',
//     },
//     '50%': {
//       transform: 'translateY(-20px)',
//     },
//   },
// };

const Testimonials = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: `url(${bgImageURL})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%',
        mt: 10,
        textAlign: 'center',
        py: 2,
        position: 'relative',
      }}
    >
      {/* {
        !isSmallScreen && (
          <>
          <Box
          component="img"
          src={orangeImageURL}
          alt="Orange"
          sx={{
            position: 'absolute',
            top: '-100px',
            left: '20px',
            width: '200px',
            height: '200px',
            filter:"drop-shadow(0px 0px 5px #000000)",
            animation: 'bounce 2s infinite',
            ...bounceAnimation,
          }}
        />
        
        <Box
          component="img"
          src={mangoImageURL}
          alt="Orange"
          sx={{
            position: 'absolute',
            bottom: '-100px',
            right: '20px',
            width: '200px',
            height: '200px',
            filter:"drop-shadow(0px 0px 5px #000000)",
            animation: 'bounce 2s infinite',
            ...bounceAnimation,
          }}
        />

          </>
          
        )
      } */}
      
     

      <Container maxWidth={'lg'}>
        <Typography
          variant="h3"
          sx={{ color: 'white', fontFamily: 'Playfair Display', fontWeight: 600 }}
        >
          Testimonials
        </Typography>

        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          initialSlide={isSmallScreen?0:1}
          centeredSlides={true}
          slidesPerView={'auto'}
          spaceBetween={isSmallScreen? 20:40}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          modules={[EffectCoverflow, Navigation]}
          navigation={!isSmallScreen}
          className="mySwiper2"
          style={{
            '--swiper-navigation-color': theme.palette.secondary.main,
            '--swiper-navigation-size': '30px',
            height: '100%',
            width: '100%',
            paddingTop: '60px',
            paddingBottom: '50px',
            marginTop: '1rem',
          }}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide
              style={{
                backgroundColor: 'white',
                width: isSmallScreen?'300px':'400px',
                height:'250px',
                borderRadius: '1rem',
              }}
              key={index}
            >
              <Card
                sx={{
                  width: '100%',
                  height: '100%',
                  background: 'transparent',
                  boxShadow: 'none',
                  margin: '0 auto',
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'visible',
                  alignItems: 'center',
                  position: 'relative',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                 <div style={{ position: "absolute", left: 0, top: 0, transform: "translate(-50%, -50%)", width: "60px", height: "60px", borderRadius: "50%", backgroundColor: theme.palette.primary.main, display: 'flex', justifyContent: "center", alignItems: "center" }} >
                  <QuoteOpening color={theme.palette.primary.main} />


                </div>

                <div style={{ position: "absolute", right: 0, bottom: 0, transform: "translate(50%, 50%)", width: "60px", height: "60px", borderRadius: "50%", backgroundColor: theme.palette.primary.main, display: 'flex', justifyContent: "center", alignItems: "center" }} >
                  <QuoteClosing color={theme.palette.primary.main} />

                </div>


                <CardMedia
                  component="img"
                  image={testimonial.profileImage}
                  alt="Profile"
                  sx={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: 5,
                    borderColor: theme.palette.primary.main,
                  }}
                />
                <CardContent style={{ textAlign: 'center', mt:"20px" }}>
                  <Typography gutterBottom variant="body1" sx={{ fontWeight: 500 }}>
                    {testimonial.text}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{fontWeight: 600 }}
                  >
                    {testimonial.author}
                  </Typography>
                  <Rating value={testimonial.rating} precision={0.5} readOnly />
                </CardContent>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </Box>
  );
};
export default Testimonials;
