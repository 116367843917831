import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector} from 'react-redux';
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ReceiptIcon from '@mui/icons-material/Receipt';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import orderService from '../../services/orderService';
import Loading from "../core/Loading"

export default function OrderCheckoutDetails() {

  const [latestOrder, setLatestOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRecentOrders = async () => {
      setLoading(true);
      try {
        const response = await orderService.getLatestOrder();
        setLatestOrder(response.data);
        console.log('Fetched recent orders:', response.data);
      } catch (error) {
        console.error('Error fetching recent orders:', error);
      }
      setLoading(false);
    };

    fetchRecentOrders();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!latestOrder) {
    return <Typography variant="body2">No order details available</Typography>;
  }

  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 500 }}>
        Order Details
      </Typography>
      <Divider flexItem />

      <Box sx={{ py: 2 }}>
        <Typography variant='body2' gutterBottom sx={{ fontWeight: 800, textTransform: "uppercase" }}>
          Bill details
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <ReceiptIcon sx={{ fontSize: "1rem" }} />
            <Typography variant='body2'>Sub Total</Typography>
          </Box>
          <Typography variant='body2'>₹ {latestOrder.subtotal -latestOrder.discount}</Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <DeliveryDiningIcon sx={{ fontSize: "1rem" }} />
            <Typography variant='body2'>Delivery Charge</Typography>
          </Box>
          <Typography variant='body2'>₹ {latestOrder.shipping_charge}</Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant='subtitle2'>Grand Total</Typography>
          <Typography variant='subtitle2'>₹ {latestOrder.grand_total}</Typography>
        </Box>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ color: "white" }}
        >
          Place Order
        </Button>
      </Box>
    </Box>
  );
}
