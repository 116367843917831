import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Navigation, Scrollbar } from 'swiper/modules';
import Container from '@mui/material/Container';
import ProductCard from '../product/ProductCard';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from "@mui/material/Box";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SectionTitle from '../core/SectionTitle';
import CategoryFilter from './CategoryFilter';

export default function BestSellers({ allProducts }) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);

  const handleCategoryChange = (category) => {
    setSelectedCategoryId(category);
  };



  const filteredProducts = selectedCategoryId === 0 ? allProducts : allProducts.filter(item => item.category_id === selectedCategoryId);

  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>

        <SectionTitle t1={"Best Sellers"} t2={"Shop Our Most Popular Items"} />
        <CategoryFilter
          selectedCategoryId={selectedCategoryId}
          onCategoryChange={handleCategoryChange}
        />


      </Box>

      <Box sx={{
        position: "relative", '& .swiper-scrollbar': {
          backgroundColor: theme.palette.grey[300],
        },
        '& .swiper-scrollbar-drag': {
          backgroundColor: theme.palette.primary.main,
          borderRadius: 2,
        }
      }}>
        <Swiper
          spaceBetween={15}
          navigation={isLargeScreen ? {
            nextEl: '.swiper-button-next-best-seller',
            prevEl: '.swiper-button-prev-best-seller',
          } : false}
          scrollbar
          modules={[Navigation, Scrollbar]}
          className="mySwiper"
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
          }}
          style={{ height: "100%", width: "100%", paddingBottom: 20, paddingInline: 5 }}
        >
          {
            filteredProducts.slice(0, 10).map(item => (
              <SwiperSlide style={{ height: "100%" }} key={item.id}>
                <ProductCard productData={item} style={{ height: "100%" }} />
              </SwiperSlide>
            ))
          }
        </Swiper>

        {isLargeScreen && (

          <>

            <Box
              className="swiper-button-prev-best-seller"
              sx={{
                position: "absolute",
                top: "50%",
                left: 0,
                zIndex: 100,
                transform: "translate(-50%,-50%)",
                backgroundColor: theme.palette.primary.main,
                color: "white",
                width: "30px",
                height: "30px",
                display: "flex",
                p: 0,
                boxShadow: 1,
                borderRadius: 1,
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <KeyboardArrowLeftIcon />
            </Box>

            <Box
              className="swiper-button-next-best-seller"
              sx={{
                position: "absolute",
                top: "50%",
                right: 0,
                p: 0,
                boxShadow: 1,
                borderRadius: 1,
                zIndex: 100,
                transform: "translate(50%,-50%)",
                backgroundColor: theme.palette.primary.main,
                color: "white",
                width: "30px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <KeyboardArrowRightIcon />
            </Box>
          </>

        )}
      </Box>
    </Container>
  );
}
