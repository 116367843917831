import axios from "axios";
import authService from "./authService";
import api from "../apiCollection";

const getAuthToken = () => {
  const user = authService.getCurrentUser();
  const token = user ? user.token : null;
  // console.log("AuthToken:", token);
  return token;
};

const getCartItems = async () => {
  try {
    const token = getAuthToken();
    // console.log("Token: ", token);
    const response = await axios.get(`${api.cart.getItems}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("getCartItems response:", response);
    return response.data;
  } catch (error) {
    console.error("Error getting cart items:", error);
    throw error;
  }
};

const addItemToCart = async (id, unit) => {
  try {
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("product_id", id);
    formData.append("product_unit", unit);

    const response = await axios.post(`${api.cart.addItem}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log("addItemToCart response:", response);
    return response.data;
  } catch (error) {
    console.error("Error adding item to cart:", error);
    throw error;
  }
};

const deleteCartItem = async (itemId) => {
  try {
    const token = getAuthToken();
    await axios.delete(`${api.cart.deleteItem}/${itemId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("deleteCartItem response: Item deleted");
    return itemId;
  } catch (error) {
    console.error("Error deleting cart item:", error);
    throw error;
  }
};

const updateCartItem = async (itemId, itemData) => {
  try {
    const token = getAuthToken();
    const response = await axios.put(`${api.cart.updateItem}/${itemId}`, itemData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("updateCartItem response:", response);
    return response.data.data;
  } catch (error) {
    console.error("Error updating cart item:", error);
    throw error;
  }
};

const getAllCoupons = async () => {
  try {
    const token = getAuthToken();
    const response = await axios.get(`${api.cart.coupons}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Fetched all coupons");
    return response.data;
  } catch (error) {
    console.error("Error fetching coupons: ", error);
    throw error;
  }
};

export default {
  getCartItems,
  addItemToCart,
  deleteCartItem,
  updateCartItem,
  getAllCoupons
};
