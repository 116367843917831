import React from 'react';
import { Box, Typography, Button, Grid, Paper, Avatar, Container, Divider, useMediaQuery } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';


// const orangeImageURL = `${process.env.REACT_APP_CLOUDINARY_BASE_URL}/v1726837704/vegingo/ai-generated-high-quality-photo-of-fresh-green-cabbage-vegetable-png_xs0yxg.webp`;
// const mangoImageURL = `${process.env.REACT_APP_CLOUDINARY_BASE_URL}/v1726837686/vegingo/pngtree-free-vector-red-chili-pepper-realistic-single-object-on-blank-white-png-image_6741302_ildtcs.png`;

// const bounceAnimation = {
//     '@keyframes bounce': {
//       '0%, 100%': {
//         transform: 'translateY(0)',
//       },
//       '50%': {
//         transform: 'translateY(-20px)',
//       },
//     },
//   };

const FreshFoodBanner = () => {
    const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;
    const isSmallScreen = useMediaQuery(theme=>theme.breakpoints.down("sm"));

    const bgIMG = `${baseCloudinaryURL}/v1726678282/vegingo/Untitled_design_2_n9rsfo.png`
    return (

        <Box sx={{width:"100%", background:`url(${bgIMG})`,backgroundRepeat:"no-repeat",backgroundPosition:"center",backgroundSize:"cover",color:"white", py:2,mt:10,position:'relative'}}>

{/* {
        !isSmallScreen && (
          <>
          <Box
          component="img"
          src={orangeImageURL}
          alt="Orange"
          sx={{
            position: 'absolute',
            top: '-100px',
            left: '20px',
            width: '200px',
            height: '200px',
            filter:"drop-shadow(0px 0px 5px #000000)",
            animation: 'bounce 2s infinite',
            ...bounceAnimation,
          }}
        />
        
        <Box
          component="img"
          src={mangoImageURL}
          alt="Orange"
          sx={{
            position: 'absolute',
            bottom: '-100px',
            right: '20px',
            width: '200px',
            height: '200px',
            filter:"drop-shadow(0px 0px 5px #000000)",
            animation: 'bounce 2s infinite',
            ...bounceAnimation,
          }}
        />

          </>
          
        )
      } */}
            <Container maxWidth="lg">
                <Grid container columnSpacing={5} rowGap={1}>
                    <Grid  item xs={12} sm={4} >
                        <Box sx={{height:"100%", width:'100%'}}>
                        <img src={`${baseCloudinaryURL}/w_400,h_500,c_fill,q_auto:good,f_auto/v1722610840/vegingo/ldy8bgfkeup67lahols9.png`} style={{ height:isSmallScreen?"300px":"100%", width: "100%", objectFit:isSmallScreen?"contain":"cover" }} alt="girl with grocery basket" />

                            </Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography sx={{fontFamily:"Playfair Display", fontWeight:600}}  variant={isSmallScreen?"h5":"h4"}  >
                            Organic & Healthy Fresh Food Provider
                        </Typography>

                        <Typography variant="body2" sx={{ marginY: 2 }}>
                            Discover the joy of eating fresh with our premium selection of organic vegetables and fruits. Our commitment to quality ensures you receive the best produce that nature has to offer, delivered straight to your home.
                        </Typography>
                        {
                        !isSmallScreen && <>
                        <Typography variant="body2" sx={{ marginY: 2 }}>
                        Our farmers adhere to the highest standards of organic farming, ensuring that every bite is packed with nutrition and flavor. Whether you're cooking for your family or preparing a gourmet meal, our fresh produce is the perfect choice for health-conscious consumers.
                    </Typography>
                    
                        </> 
                        }
                        
                        <Box sx={{ display: "flex", gap: 2 }}>

                            <Paper elevation={4} sx={{ display: "flex", alignItems: "center", p: 1, color: "white", background: 'transparent', gap: 1 }} >
                                <CheckCircleOutline />
                                <Typography variant='body1'>Natural Products</Typography>

                            </Paper>


                            <Paper elevation={4} sx={{ display: "flex", alignItems: "center", p: 1, color: "white", background: 'transparent', gap: 1 }} >
                                <CheckCircleOutline />
                                <Typography variant='body1'>Fresh Everyday</Typography>

                            </Paper>


                        </Box>

                        <Divider flexItem variant='middle' sx={{ borderColor: "white", my: 2 }} />
                        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                            <img src={`${baseCloudinaryURL}/v1722610886/vegingo/qiookbsnjnayqkiiituw.png`} style={{ width: "80px" }} alt='vegetable crate' />

                            <Typography variant={isSmallScreen?"h7":"h6"} sx={{ display: 'flex', alignItems: 'center', marginY: 2 }}>
                                Enjoy fresh and high-quality products delivered to your doorstep every day.
                            </Typography>

                        </Box>

                        <Link to="/about-us">
                            <Button size='small' variant="contained" color="primary" sx={{ marginTop: 2, color: "white" }}>
                                Discover More
                            </Button>
                        </Link>

                    </Grid>
                </Grid>

            </Container>

        </Box>


        
    );
};

export default FreshFoodBanner;
