import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import React from 'react';
import AboutCard from '../components/about/AboutCard';
import PageTitle from '../components/core/PageTitle';
import { useTheme } from '@emotion/react';
import SectionTitle from '../components/core/SectionTitle';
import TeamCard from '../components/about/TeamCard';

const teamMembers = [
  {
    name: 'Founder Name',
    title: 'Founder & CEO',
    imageUrl: '[founder-image-url]',
  },
  {
    name: 'Team Member Name',
    title: 'Chief Operations Officer',
    imageUrl: '[team-member-image-url]',
  },

];

export default function AboutUs() {
  const theme = useTheme();

  return (
    <>
      {/* <PageTitle title="About" /> */}
      <Container maxWidth="lg">
        <Box sx={{ my: { xs: 5, md: 10 }, display: "flex", flexDirection: { xs: 'column', md: 'row' }, gap: 4 }}>
          <AboutCard
            title={"Fruits"}
            image="https://images.pexels.com/photos/1300975/pexels-photo-1300975.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          />
          <AboutCard
            title={"Vegetables"}
            image="https://images.pexels.com/photos/1435904/pexels-photo-1435904.jpeg"
          />
        </Box>
        <Box sx={{ p: { xs: 2, md: 4 }, bgcolor: theme.palette.primary.main, color: "white", borderRadius: "0.3rem" }}>
          <Typography variant="h6" component="p">
            Welcome to VeginGo, your number one source for fresh vegetables and fruits. We're dedicated to providing you the very best of fresh produce, with an emphasis on quality, reliability, and customer service.
          </Typography>
          <Typography variant="h6" component="p" sx={{ marginTop: 2 }}>
            Founded in [Year], VeginGo has come a long way from its beginnings in [starting location]. When [founder name] first started out, their passion for providing fresh, high-quality produce drove them to start their own business.
          </Typography>
          <Typography variant="h6" component="p" sx={{ marginTop: 2 }}>
            We hope you enjoy our products as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us.
          </Typography>
        </Box>
        <Box sx={{ my: { xs: 5, md: 10 } }}>
          <SectionTitle title="Our Team" subtitle="Meet the people behind VeginGo." />
          <Grid container spacing={4} sx={{ marginTop: 4 }}>
            {teamMembers.map((member, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <TeamCard name={member.name} title={member.title} imageUrl={member.imageUrl} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
}
