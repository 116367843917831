import React, { useEffect } from 'react';
import PageTitle from '../components/core/PageTitle';
import Container from "@mui/material/Container";
import CartList from '../components/cart/CartList';
import CartCheckout from '../components/cart/CartCheckout';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SimilarProducts from '../components/cart/SimilarProducts';
import AddressSelector from '../components/cart/AddressSelector';
import { useDispatch, useSelector } from 'react-redux';
import EmptyCart from '../components/cart/EmptyCart';
import { fetchLocalCart, fetchServerCart } from '../redux/slices/cartSlice';
import Loading from "../components/core/Loading"
import { fetchAddress } from '../redux/slices/addressSlice';

export default function Cart() {
  
  const address = useSelector(state=>state.address);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const localCart = useSelector((state) => state.cart.localCart);
  const serverCart = useSelector((state) => state.cart.serverCart);
  
  useEffect(() => {
    if (isAuthenticated ) {
      dispatch(fetchServerCart()); 
      dispatch(fetchAddress());
    } else {
      dispatch(fetchLocalCart()); 
    }
  }, [dispatch]);
  

  const {status} = useSelector(state=>state.cart);
  const cart = isAuthenticated ? serverCart : localCart;
  if(status === "loading" || address.status === "loading"){
    return <Loading/>
  }
  
  
  
  
  
  const isEmpty = cart.products.length === 0;

 

  return (
    <Box >
      <PageTitle title="Cart" />

      <Container sx={{ minHeight: "60vh", my: 5 }} maxWidth="lg">

        {
          isEmpty ? <EmptyCart/> : (
            <Grid columnSpacing={2} rowGap={5} container>
            <Grid item md={8} xs={12}>
              <AddressSelector />
              <CartList />
              <SimilarProducts />
            </Grid>
            <Grid item md={4} xs={12}>
              <Paper elevation={2} sx={{ p: 2, position: 'sticky', top: 20 }}>
                <CartCheckout />
  
              </Paper>
            </Grid>
          </Grid>
          )

        }
       
      </Container>
    </Box>
  );
}
