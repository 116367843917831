import React from 'react'
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from '@mui/material/Typography'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import Paper from "@mui/material/Paper"
import  Divider from '@mui/material/Divider'
import useMediaQuery  from '@mui/material/useMediaQuery'
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';


const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;

const policyFrameIMG = `${baseCloudinaryURL}/w_300,h_300,c_scale,q_auto,f_auto/v1722407331/vegingo/policy-frame_qlbahm.png`;
const bgIMG = `${baseCloudinaryURL}/q_auto/v1722419096/vegingo/Subscribe-bg_nyvzyr.jpg`;


const items = [
  {
    id: 1,
    title: "Cash On Delivery",
    description: "Convenient payment at your doorstep.",
    icon: <AccountBalanceWalletOutlinedIcon className="policy-icon" fontSize='large' sx={{ color: "#759f40", transformStyle: "preserve-3d", transition: "transform 400ms" }} />
  },
  {
    id: 2,
    title: "Free Shipping",
    description: "Enjoy no-cost delivery on all orders.",
    icon: <LocalShippingOutlinedIcon className="policy-icon" fontSize='large' sx={{ color: "#759f40", transformStyle: "preserve-3d", transition: "transform 400ms" }} />
  },
  {
    id: 3,
    title: "Secure Payment",
    description: "Your transactions are safe and protected.",
    icon: <ShieldOutlinedIcon className="policy-icon" fontSize='large' sx={{ color: "#759f40", transformStyle: "preserve-3d", transition: "transform 400ms" }} />
  },
  {
    id: 4,
    title: "Money Back Guarantee",
    description: " Get refunds with confidence if needed.",
    icon: <AttachMoneyOutlinedIcon className="policy-icon" fontSize='large' sx={{ color: "#759f40", transformStyle: "preserve-3d", transition: "transform 400ms" }} />
  },
]

const PolicyBox = ({ item }) => {

  return (

    <Paper elevation={5} sx={{ display: "flex",flexDirection:{xs:"column",md:"row"},textAlign:{xs:"center",md:"left"} ,alignItems: "center", width: "100%", height: "100%",borderRadius:1,p:1,"&:hover .policy-icon": { transform: "rotateY(360deg)" } }}>
      <Box sx={{ width:"100px", height:{xs:"80px", md:"100px"}, background: `url(${policyFrameIMG})`, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat", display: "flex", justifyContent: "center", alignItems: "center" }}>

        {item.icon}

      </Box>

      <Box >
        <Typography sx={{ textTransform: "uppercase", fontWeight: 700 }} variant='subtitle2'>{item.title}</Typography>
        <Typography variant='subtitle2' sx={{color:'GrayText'}}>{item.description}</Typography>
      </Box>

    </Paper>

  )
}

export default function ProductPolicy() {
  return (
    <Box sx={{width:"100%",height:{xs:'100%',md:"40vh"}, background:`url(${bgIMG})`, backgroundRepeat:'no-repeat', backgroundSize:"cover", backgroundPosition:"bottom"}}>
        <Container maxWidth="lg" sx={{mt:5, height:"100%", display:"flex",justifyContent:"center",alignItems:"center",py:2}}>
 
 <Grid container columnSpacing={3} rowSpacing={3}>

 {
     items.map((item, index) => (
       <Grid md={3} xs={6} item key={item.id}>
           <PolicyBox item={item} />
       </Grid>
     ))
   }

 </Grid>
</Container>


    </Box>

  
  )
}
