import axios from "axios";
import authService from "./authService";
import api from "../apiCollection";
// import { saveAs } from "file-saver";

const getAuthToken = () => {
  const user = authService.getCurrentUser();
  const token = user ? user.token : null;
  console.log("AuthToken:", token);
  return token;
};

const getLatestOrder = async () => {
  try {
    const token = getAuthToken();
    const response = await axios.get(`${api.order.latestOrder}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("getLatestOrder response:", response);
    return response.data;
  } catch (error) {
    console.error("Error getting recent orders:", error);
    throw error;
  }
};

const getOrderHistory = async () => {
  try {
    const token = getAuthToken();
    const response = await axios.get(`${api.order.orderHistory}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("getOrderHistory response:", response);
    return response.data;
  } catch (error) {
    console.error("Error getting order history:", error);
    throw error;
  }
};

const createOrderFromCart = async (addressId, orderNote, coupon, deliveryCharge) => {
  // console.log(coupon)
  try {
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("address_id", addressId);
    formData.append("notes", orderNote);
    formData.append("coupon_code", coupon || null);
    formData.append("shipping_charge", deliveryCharge);
    const response = await axios.post(
      `${api.order.createOrderFromCart}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("createOrderFromCart response:", response);
    return response.data;
  } catch (error) {
    console.error("Error creating order from cart:", error);
    throw error;
  }
};

const createOrderFromProduct = async (productId, quantity) => {
  try {
    const token = getAuthToken();
    const formData = new FormData();
    formData.append("product_id", productId);
    formData.append("quantity", quantity);

    const response = await axios.post(
      `${api.order.createOrderFromProduct}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("createOrderFromProduct response:", response);
    return response.data;
  } catch (error) {
    console.error("Error creating order from product:", error);
    throw error;
  }
};

const downloadInvoice = async (orderId) => {
  try {
    const token = getAuthToken();
    const response = await axios.get(
      `${api.order.downloadInvoice}/${orderId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: "blob",
      }
    );

    // const blob = new Blob([response.data], { type: "application/pdf" });
    // saveAs(blob, `invoice_${orderId}.pdf`);
    console.log("Invoice downloaded successfully");
  } catch (error) {
    console.error("Error downloading invoice:", error);
    throw error;
  }
};

export default {
  getLatestOrder,
  getOrderHistory,
  createOrderFromCart,
  createOrderFromProduct,
  downloadInvoice,
};
