import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useApiFetch from '../hooks/useApiFetch';
import Loading from '../components/core/Loading';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Share from '@mui/icons-material/Share';
import PageTitle from '../components/core/PageTitle';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LabelIcon from '@mui/icons-material/Label';
import CommentBox from '../components/blog/CommentBox';
import api from '../apiCollection';

const BlogDetail = () => {
    const location = useLocation();
    const blogName = decodeURIComponent(location.pathname.split('/')[2]);
    const {
        data: allBlogsData,
        loading: allBlogsLoading,
        error: allBlogsError,
    } = useApiFetch(api.blog.getAll);

    const [blogId, setBlogId] = useState(null);
    const {
        data: blogData,
        loading: blogLoading,
        error: blogError,
    } = useApiFetch(`${api.blog.getAll}/${blogId}`);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    useEffect(() => {
        if (allBlogsData) {
            const foundBlog = allBlogsData.data.find(
                (blog) => blog.title === blogName
            );
            if (foundBlog) {
                setBlogId(foundBlog.id);
            }
        }
    }, [allBlogsData, blogName]);

    if (allBlogsLoading || blogLoading) {
        return <Loading />;
    }

    if (allBlogsError || blogError) {
        return <p>Error fetching data</p>;
    }

    if (!blogData) {
        return <p>Blog not found</p>;
    }

    const blogComments = blogData.data.blog_comments;

    return (
        <Box minWidth="100%">
            <PageTitle title={'Blogs'} />
            <Container maxWidth="lg">
                <Box my={4}>
                    <Box
                        sx={{
                            my: 4,
                            width: '100%',
                            height: '100%',
                            maxHeight: '400px',
                            borderRadius: '0.4rem',
                            overflow: 'hidden',
                        }}
                    >
                        <img
                            src={blogData.data.image}
                            alt="Fruits and Vegetables"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </Box>

                    <Typography variant="h4" component="h1" gutterBottom>
                        {blogData.data.title}
                    </Typography>
                    <Box
                        display="flex"
                        flexWrap={'wrap'}
                        alignItems="center"
                        mb={2}
                    >
                        <Box display="flex" alignItems="center">
                            <Box display="flex" alignItems="center" gap={1}>
                                <PersonIcon color="primary" fontSize="xs" />
                                <Typography
                                    whiteSpace="nowrap"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {blogData.data.author}
                                </Typography>
                            </Box>

                            <Divider
                                sx={{ mx: 1 }}
                                orientation="vertical"
                                flexItem
                                color="white"
                            />

                            <Box display="flex" alignItems="center" gap={1}>
                                <CalendarTodayIcon
                                    color="primary"
                                    fontSize="xs"
                                />
                                <Typography
                                    whiteSpace="nowrap"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {formatDate(blogData.data.updated_at)}
                                </Typography>
                            </Box>
                            <Divider
                                sx={{ mx: 1 }}
                                orientation="vertical"
                                flexItem
                                color="white"
                            />
                            <Box display="flex" alignItems="center" gap={1}>
                                <LabelIcon color="primary" fontSize="xs" />
                                <Typography
                                    whiteSpace="nowrap"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {blogData.data.tags}
                                </Typography>
                            </Box>
                        </Box>
                        <Box ml="auto">
                            <Button
                                startIcon={<Share />}
                                variant="contained"
                                color="primary"
                                sx={{ color: 'white', borderRadius: '1.2rem' }}
                            >
                                Share
                            </Button>
                        </Box>
                    </Box>
                    <Typography variant="body1" paragraph>
                        {blogData.data.content}
                    </Typography>
                    <Divider />
                    <Box mt={4}>
                        <CommentBox
                            blogId={blogId}
                            blogComments={blogComments}
                        />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default BlogDetail;
