import { Container, Grid, Box, Button, Typography, CircularProgress, useMediaQuery } from '@mui/material'
import React from 'react'
import ProductCard from '../components/product/ProductCard';
import PageTitle from '../components/core/PageTitle';
import { api } from '../apiCollection';
import Loading from '../components/core/Loading';
import { toast } from 'react-toastify';
import usePaginatedData from '../hooks/usePaginatedData';
import ProgressBar from '../components/core/ProgressBar';
import { useTheme } from '@mui/material/styles';
import Error from '../components/core/Error';


export default function Shop() {

  const productUrl = api.products.getAll;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme=>theme.breakpoints.down("sm"));

  const { data, currentPage, loading, error, loadMore, totalCount } = usePaginatedData(productUrl);



  // console.log(data);



  if (loading && currentPage === 1) {
    return <Loading />
  }
  if (error) {
    toast.error("Error fetching the data")
    return <Error code={error.request.status}/>;
  }

  let progress = (data.length / totalCount) * 100;
 


  return (

    <Box >
      {/* <PageTitle title="All Collections" /> */}
      <Container maxWidth="lg">


        <Grid container spacing={isSmallScreen ? 2: 5} justifyContent={"center"} sx={{ my: 5 }}>
          {
            data.map((product, index) => (
              <Grid item mb={isSmallScreen?3:0} key={index} xs={6} sm={6} md={3} lg={3}>
                <ProductCard productData={product} flexDirection={"column"} button={true} />
              </Grid>
            ))
          }

        </Grid>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", my: 10, gap: 2 }}>
          <Typography gutterBottom variant='h7' sx={{fontWeight:"500"}}>You are viewing {data.length} of {totalCount} products</Typography>
          <ProgressBar  progress={progress} />
          {/* <LoadButton loading={loading} onLoadMore={loadMore} disabled={data.length === totalCount ? true : false} /> */}
          <Button size='small' variant='contained' disabled={data.length === totalCount ? true : false} onClick={loadMore} sx={{bgcolor:theme.palette.primary.main, color:"white", width:"120px", whiteSpace:"nowrap"}}>
            {
              loading? <CircularProgress size={20} sx={{color:"white"}}/> : <span>Load More</span>
            }
          </Button>

        </Box>

      </Container>
    </Box>

  )
}
