import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Autoplay, Pagination } from 'swiper/modules';
import Box from "@mui/material/Box";
import useAllData from '../../hooks/useAllData';
import api from '../../apiCollection';
import Error from '../core/Error';
import FlashDealCard from './FlashDealCard';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Container } from '@mui/material';

const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;
const bgImageURL = `${baseCloudinaryURL}/v1728826142/vegingo/pngtree-green-small-fresh-fruit-and-vegetable-promotion-e-commerce-banner-picture-image_1117115_jr5hcc.png`;

export default function FlashDeals() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 
  const { allData, loading, error } = useAllData(api.products.getAll);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <Error code={error.request.status} />;
  }

  return (
    <Box sx={{
      background: `url(${bgImageURL})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      py: 5,
      mt: 10
    }}>
      <Container maxWidth="lg">
        <Box sx={{ position: 'relative' }}>
          <Swiper
            autoplay={{ delay: 2500 }}
            slidesPerView={isSmallScreen ? 1 : 2}
            spaceBetween={50}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
            className="mySwiper3"
            style={{
              zIndex: 10,
              '--swiper-pagination-bullet-inactive-color': theme.palette.grey[500],
              '--swiper-pagination-bullet-inactive-opacity': '0.6',
              '--swiper-pagination-bullet-size': '15px',
              '--swiper-pagination-bullet-horizontal-gap': '6px',
              '--swiper-pagination-color': theme.palette.primary.main,
              paddingInline: 5,
              paddingBottom: 50
            }}
          >
            {allData.slice(0, 5).map((product) => (
              <SwiperSlide style={{ height: "100%" }} key={product.id}>
                <FlashDealCard product={product} />
              </SwiperSlide>
            ))}
          </Swiper>
          <Box sx={{
            bgcolor: "red",
            textTransform: 'uppercase',
            position: "absolute",
            right: -10,
            top: -10,
            p: 1,
            zIndex: 10,
            fontSize: "1rem",
            color: "white",
            boxShadow: 2
          }}>
            Hot Deals
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
