import React, { useEffect } from 'react';
import Drawer from "@mui/material/Drawer"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import CloseIcon from '@mui/icons-material/Close';
import CartListMobile from './CartListMobile';
import Button from "@mui/material/Button"
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from "@mui/material/styles"
import { Link } from 'react-router-dom';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { fetchCart, fetchLocalCart, fetchServerCart } from '../../redux/slices/cartSlice';
import EmptyCart from './EmptyCart';
import { CircularProgress } from '@mui/material';

export default function CartDrawer({ toggleDrawer, drawerOpen }) {
  const dispatch = useDispatch();
  
  // Assuming you have a selector for checking if the user is authenticated
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // Selecting the appropriate cart state based on authentication
  const localCart = useSelector((state) => state.cart.localCart);
  const serverCart = useSelector((state) => state.cart.serverCart);
  const cart = isAuthenticated ? serverCart : localCart;
  console.log(cart);

  const theme = useTheme();

  const isEmpty = cart.products.length === 0;





  return (
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>

      <Box sx={{ width: "400px", height: "100vh", position: "relative", overflow: "hidden", display: "flex", flexDirection: "column" }}>

        {
          cart.status === "loading" ? <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress size={20} />
          </Box> : (



            isEmpty ? <EmptyCart onClose={toggleDrawer} /> :
              <>
                <Typography sx={{ ml: 2, mt: 2, fontWeight: 800 }} variant="h6" gutterBottom>Your Cart</Typography>

                <IconButton
                  sx={{ position: 'absolute', top: 8, right: 8 }}
                  onClick={toggleDrawer}
                >
                  <CloseIcon />
                </IconButton>

                <Box className="scroll" sx={{
                  overflowY: "auto", "&::-webkit-scrollbar-thumb": {
                    background: theme.palette.primary.main
                  }
                }}>
                  <CartListMobile onClose={toggleDrawer} />
                </Box>

                <Box sx={{ textAlign: "center", p: 2, bgcolor: "background.default", boxShadow:2 }}>
                  {
                    cart.total > 200 && <Box sx={{ bgcolor: theme.palette.primary.main, mb: 1, display: "flex", justifyContent: "center", alignItems: "center" }}><Typography variant="caption" sx={{ textTransform: "uppercase", fontWeight: 600, color: "whitesmoke" }}>Congratulations! You've got free shipping
                    </Typography>
                      <LocalShippingOutlinedIcon sx={{ color: "white", fontSize: "1.2rem", ml: 1 }} /></Box>

                  }
                  <Typography gutterBottom variant='h6' sx={{ fontWeight: "800" }}>Total : <span style={{ color: theme.palette.primary.main }}>₹ {cart.total}</span></Typography>

                  <Link to="/cart">
                    <Button onClick={toggleDrawer} variant='contained' color="primary" fullWidth sx={{ color: "white" }}>View Cart</Button>

                  </Link>


                </Box></>


          )
        }

      </Box>





    </Drawer>
  );
}
