import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import cartService from "../../services/cartService";
import { TableRowsTwoTone } from "@mui/icons-material";

// Initial State
const initialState = {
  serverCart: {
    products: [],
    total: 0,
    count: 0,
  },
  localCart: {
    products: [],
    total: 0,
    count: 0,
  },
  status: "idle",
  loading: {},
  error: null,
};

// Fetch Server Cart
export const fetchServerCart = createAsyncThunk(
  "cart/fetchServerCart",
  async (_, { rejectWithValue }) => {
    try {
      const response = await cartService.getCartItems();
      console.log(response.data);
      const total = response.data.reduce(
        (total, item) =>
          total + parseInt(item.product.discounted_price) * item.quantity,
        0
      );
      return { items: response.data, total };
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

export const mergeCart = createAsyncThunk(
  "cart/mergeCart",
  async (_, { dispatch, getState }) => {
    // Fetch server cart
    const serverCart = await dispatch(fetchServerCart()).unwrap();
    console.log("Server Cart",serverCart);

    // Check if server cart is empty
    if (serverCart.items.length === 0) {
      const localCart = JSON.parse(localStorage.getItem("localCart")) || [];

      // Add each item from local cart to server cart
      for (const item of localCart) {
        await dispatch(addItemToServerCart(item));
      }

      // Clear local cart after merging
      dispatch(clearLocalCart());
    }
  }
);


// Fetch Local Cart
export const fetchLocalCart = createAsyncThunk(
  "cart/fetchLocalCart",
  async () => {
    const localCart = JSON.parse(localStorage.getItem("localCart")) || [];
    const total = localCart.reduce(
      (total, item) =>
        total + parseInt(item.product.discounted_price || 0) * item.quantity,
      0
    );
    return { items: localCart, total };
  }
);

// Add Item to Server Cart
export const addItemToServerCart = createAsyncThunk(
  "cart/addItemToServerCart",
  async (item, { rejectWithValue }) => {
    try {
      const data = await cartService.addItemToCart(item.id, item.product_unit);
      return {product:item, quantity:1};
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

// Add Item to Local Cart
export const addItemToLocalCart = createAsyncThunk(
  "cart/addItemToLocalCart",
  async (item) => {
    const localCart = JSON.parse(localStorage.getItem("localCart")) || [];
    const existingItemIndex = localCart.findIndex(
      (cartItem) => cartItem.product.id === item.id
    );

    if (existingItemIndex !== -1) {
      localCart[existingItemIndex].quantity += 1;
    } else {
      localCart.push({ product: item, quantity: 1 });
    }

    localStorage.setItem("localCart", JSON.stringify(localCart));

    return { product:item, quantity: 1 };
  }
);

// Remove Item from Server Cart
export const removeItemFromServerCart = createAsyncThunk(
  "cart/removeItemFromServerCart",
  async ({ productId }, { rejectWithValue }) => {
    try {
      await cartService.deleteCartItem(productId);
      return productId;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

// Remove Item from Local Cart
export const removeItemFromLocalCart = createAsyncThunk(
  "cart/removeItemFromLocalCart",
  async ({ productId }) => {
    const localCart = JSON.parse(localStorage.getItem("localCart")) || [];
    const updatedCart = localCart.filter(
      (item) => item.product.id !== productId
    );
    localStorage.setItem("localCart", JSON.stringify(updatedCart));
    return productId;
  }
);

// Update Item Quantity in Server Cart
export const updateItemQuantityInServerCart = createAsyncThunk(
  "cart/updateItemQuantityInServerCart",
  async ({ itemId, quantity, product_unit = "kg" }, { rejectWithValue }) => {
    try {
      const response = await cartService.updateCartItem(itemId, {
        quantity,
        product_unit,
      });
      return { id: itemId, quantity: response.quantity };
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

// Update Item Quantity in Local Cart
export const updateItemQuantityInLocalCart = createAsyncThunk(
  "cart/updateItemQuantityInLocalCart",
  async ({ itemId, quantity }) => {
    let localCart = JSON.parse(localStorage.getItem("localCart")) || [];
    const itemIndex = localCart.findIndex((item) => item.product.id === itemId);
    if (itemIndex !== -1) {
      localCart[itemIndex].quantity = quantity;
      localStorage.setItem("localCart", JSON.stringify(localCart));
      return { id: itemId, quantity };
    } else {
      return Promise.reject("Item not found in local cart");
    }
  }
);

// Cart Slice
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    clearServerCart: (state) => {
      state.serverCart = { products: [], total: 0, count: 0 };
    },
    clearLocalCart: (state) => {
      state.localCart = { products: [], total: 0, count: 0 };
    },
  },
  extraReducers: (builder) => {
    // Server Cart
    builder
      .addCase(fetchServerCart.pending, (state) => {
        state.status = "loading";
      })
     
  
      .addCase(fetchServerCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.serverCart.products = action.payload.items;
        state.serverCart.total = action.payload.total;
        state.serverCart.count = action.payload.items.length;
      })
      .addCase(fetchServerCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(mergeCart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(mergeCart.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(mergeCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addItemToServerCart.fulfilled, (state, action) => {
        const addedProduct = action.payload;
        const existingItem = state.serverCart.products.find(
          (item) => item.product.id === addedProduct.product.id
        );

        if (existingItem) {
          existingItem.quantity += addedProduct.quantity;
        } else {
          state.serverCart.products.push(addedProduct);
          state.serverCart.count += 1;
        }
        state.serverCart.total +=
          parseInt(addedProduct.product.discounted_price) * addedProduct.quantity;

        toast.success("Item added to cart", {
          position:"top-center"
        })
      })
      .addCase(removeItemFromServerCart.fulfilled, (state, action) => {
        const itemId = action.payload;
        const item = state.serverCart.products.find(
          (item) => item.product.id === itemId
        );
        if (item) {
          state.serverCart.total -=
            parseInt(item.product.discounted_price) * item.quantity;
          state.serverCart.products = state.serverCart.products.filter(
            (item) => item.product.id !== itemId
          );
          state.serverCart.count -= 1;
        }
      })
      .addCase(updateItemQuantityInServerCart.fulfilled, (state, action) => {
        const { id, quantity } = action.payload;
        const existingItem = state.serverCart.products.find(
          (item) => item.product.id === id
        );
        if (existingItem) {
          state.serverCart.total -=
            parseInt(existingItem.product.discounted_price) *
            existingItem.quantity;
          existingItem.quantity = quantity;
          state.serverCart.total +=
            parseInt(existingItem.product.discounted_price) * quantity;
        }
      });

    // Local Cart
    builder
      .addCase(fetchLocalCart.fulfilled, (state, action) => {
        state.localCart.products = action.payload.items;
        state.localCart.total = action.payload.total;
        state.localCart.count = action.payload.items.length;
      })
      .addCase(addItemToLocalCart.fulfilled, (state, action) => {
        const addedProduct = action.payload;
        const existingItem = state.localCart.products.find(
          (item) => item.product.id === addedProduct.id
        );

        if (existingItem) {
          existingItem.quantity += action.payload.quantity;
        } else {
          state.localCart.products.push({
            product: addedProduct,
            quantity: action.payload.quantity,
          });
          state.localCart.count += 1;
        }
        state.localCart.total +=
          parseInt(addedProduct.discounted_price) * action.payload.quantity;

          toast.success("Item added to cart", {
            position:"top-center"
          })
      })
      .addCase(removeItemFromLocalCart.fulfilled, (state, action) => {
        const itemId = action.payload;
        const item = state.localCart.products.find(
          (item) => item.product.id === itemId
        );
        if (item) {
          state.localCart.total -=
            parseInt(item.product.discounted_price) * item.quantity;
          state.localCart.products = state.localCart.products.filter(
            (item) => item.product.id !== itemId
          );
          state.localCart.count -= 1;
        }
      })
      .addCase(updateItemQuantityInLocalCart.fulfilled, (state, action) => {
        const { id, quantity } = action.payload;
        const existingItem = state.localCart.products.find(
          (item) => item.product.id === id
        );
        if (existingItem) {
          state.localCart.total -=
            parseInt(existingItem.product.discounted_price) *
            existingItem.quantity;
          existingItem.quantity = quantity;
          state.localCart.total +=
            parseInt(existingItem.product.discounted_price) * quantity;
        }
      });
  },
});

export const { clearServerCart, clearLocalCart } = cartSlice.actions;
export default cartSlice.reducer;
