import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import {
    createAddress,
    modifyAddress,
    setDefaultAddress,
} from '../../redux/slices/addressSlice';
import Map from '../../map/Map';
import { unwrapResult } from '@reduxjs/toolkit';
import axios from 'axios';
import api from '../../apiCollection';
import { debounce } from 'lodash';

const AddressForm = ({ onClose, editingAddress }) => {
    console.log('Editing Address :', editingAddress);
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState({
        flat: '',
        floor: '',
        area: '',
        landmark: '',
        city: '',
        state: '',
        country: '',
        name: '',
        phone: '',
        pincode: '',
    });

    const [pincodeError, setPincodeError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const { addresses: reduxAddress } = useSelector((state) => state.address);
    const dispatch = useDispatch();

    useEffect(() => {
        if (editingAddress) {
            const [flat, floor, area] =
                editingAddress.building_name_area.split(',');
            setAddress({
                flat: flat || '',
                floor: floor || '',
                area: area || '',
                landmark: editingAddress.landmark || '',
                city: editingAddress.city || '',
                state: editingAddress.state || '',
                country: editingAddress.country || '',
                name: editingAddress.user_name || '',
                phone: editingAddress.phone_number || '',
                pincode: editingAddress.pincode || '',
            });
        }
    }, [editingAddress]);

    useEffect(() => {
        const debouncedValidatePhoneNumber = debounce(
            validatePhoneNumber,
            1000
        );
        debouncedValidatePhoneNumber(address.phone);

        return () => debouncedValidatePhoneNumber.cancel();
    }, [address.phone]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'pincode') {
            setPincodeError('');
        }
        if (name === 'phone') {
            setPhoneError('');
        }

        if (name === 'pincode' && value.length > 6) {
            return;
        }

        setAddress((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        if (name === 'pincode' && value.length === 6) {
            handlePincodeChange(value);
        }
    };

    const handlePincodeChange = async (pincode) => {
        try {
            const response = await axios.get(`${api.pincodeApi}/${pincode}`);
            if (response.data.Status === 'Success') {
                const postOffice = response.data.PostOffice[0];
                setAddress((prevState) => ({
                    ...prevState,
                    city: postOffice.District,
                    state: postOffice.State,
                    country: postOffice.Country,
                }));
            } else {
                setPincodeError('Invalid Pincode');
            }
        } catch (error) {
            console.error('Error fetching location data:', error);
            setPincodeError('Error fetching location data');
        }
    };

    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^[6-9]\d{9}$/;
        if (phone && !phoneRegex.test(phone)) {
            setPhoneError('Phone number invalid');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (editingAddress) {
            const id = editingAddress.id;
            await dispatch(modifyAddress({ id, address }));
        } else {
            const resultAction = await dispatch(createAddress(address));
            const createdAddress = unwrapResult(resultAction);

            if (createdAddress && createdAddress.id) {
                await dispatch(setDefaultAddress(createdAddress.id));
            }
        }

        setLoading(false);
        onClose();
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Grid container columnSpacing={2} rowGap={5}>
                <Grid item md={6} xs={12} order={{xs:2,md:1}}>
                    <Map />
                </Grid>
                <Grid item md={6} xs={12}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    required
                                    label="Flat / House no / Building name"
                                    fullWidth
                                    name="flat"
                                    value={address.flat}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    size="small"
                                    label="Floor"
                                    fullWidth
                                    name="floor"
                                    value={address.floor}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    required
                                    label="Area / Sector / Locality"
                                    fullWidth
                                    name="area"
                                    value={address.area}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    required
                                    type="number"
                                    label="Pincode"
                                    fullWidth
                                    name="pincode"
                                    value={address.pincode}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 6 }}
                                    error={Boolean(pincodeError)}
                                    helperText={pincodeError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    required
                                    label="Nearby landmark"
                                    fullWidth
                                    name="landmark"
                                    value={address.landmark}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    required
                                    label="City"
                                    fullWidth
                                    name="city"
                                    value={address.city}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    required
                                    label="State"
                                    fullWidth
                                    name="state"
                                    value={address.state}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    label="Country"
                                    required
                                    fullWidth
                                    name="country"
                                    value={address.country}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    required
                                    label="Your name"
                                    fullWidth
                                    name="name"
                                    value={address.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    required
                                    label="Your phone number"
                                    fullWidth
                                    type="tel"
                                    name="phone"
                                    value={address.phone}
                                    onChange={handleChange}
                                    error={Boolean(phoneError)}
                                    helperText={phoneError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            color: 'white',                                            
                                            width: '150px',
                                        }}
                                    >
                                        {loading ? (
                                            <CircularProgress
                                                size={20}
                                                sx={{ color: 'white' }}
                                            />
                                        ) : editingAddress ? (
                                            'Update Address'
                                        ) : (
                                            'Save Address'
                                        )}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </>
    );
};

export default AddressForm;
