import React from 'react'
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Button from "@mui/material/Button"
import InstallIcon from "@mui/icons-material/InstallMobile"
import { Link } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;
const bgImageURL = `${baseCloudinaryURL}/v1726938778/Order_now_mcvdx8.png`;
const mobileImageURL = `${process.env.REACT_APP_CLOUDINARY_BASE_URL}/v1726938891/Untitled_design__8_-removebg-preview_epqkzd.png`;

export default function InstallApp() {
    const isSmallScreen = useMediaQuery(theme=>theme.breakpoints.down("sm"));
    return (
        <Box sx={{height:{xs:"100%",md:"60vh"},background: `url(${bgImageURL})`, backgroundPosition: 'center', backgroundRepeat: "no-repeat", backgroundSize: "cover", mt: 5 }}>
            <Container maxWidth="lg" sx={{ height: '100%', py: 2 }} >
                <Grid container>
                    <Grid sx={{display:'flex',alignItems:"center"}} item md={6}>
                        <Box>
                        <Typography variant={isSmallScreen?"h5":"h3"} gutterBottom sx={{fontFamily:"Playfair Display",color:'black' ,fontWeight:600}}>
                            Get Our App Today!
                        </Typography>
                        <Typography variant={isSmallScreen?"body1":"h6"} color={"textSecondary"} paragraph>
                            Download the app and get access to exclusive features. Track your orders, receive special offers, and much more.
                        </Typography>
                        
                        <Link to="/install">
                        <Button  startIcon={<InstallIcon/>} variant='contained' color="secondary">Download</Button>
                        </Link>
                       
                        
                        </Box>
                      
                    </Grid>
                    <Grid sx={{ display: 'flex', justifyContent: "center", alignItems: 'center' }} item md={6}>
                        <img style={{ height: "100%", width: '100%', maxWidth: "400px", objectFit: "contain" }} src={mobileImageURL} />
                    </Grid>
                </Grid>
            </Container>

        </Box>

    )
}
