import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Navigation, Scrollbar } from 'swiper/modules';
import Container from '@mui/material/Container';
import ProductCard from '../product/ProductCard';
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import useMediaQuery from '@mui/material/useMediaQuery';
import SectionTitle from '../core/SectionTitle';

export default function SpecialProducts({ specialProducts }) {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  
  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      <SectionTitle t1={"Special Products"} t2={"Featured and Exclusive Finds"} />

      <Box sx={{ position: "relative", '& .swiper-scrollbar': {
                        backgroundColor: theme.palette.grey[300],
                    },
                    '& .swiper-scrollbar-drag': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                    } }}>
        <Swiper
          scrollbar
          navigation={isLargeScreen ? { 
            nextEl: '.swiper-button-next-special-products',
            prevEl: '.swiper-button-prev-special-products',
          } : false}
          modules={[Navigation, Scrollbar]}
          className="mySwiper"
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
          }}
          style={{ height: "100%", width: "100%", paddingInline: 5, paddingBottom: 20 }}
        >
          {specialProducts.slice(0, 10).map(item => (
            <SwiperSlide style={{ height: "100%" }} key={item.id}>
              <ProductCard productData={item} flexDirection={"row"} button={true} />
            </SwiperSlide>
          ))}
        </Swiper>

       

        {isLargeScreen && ( 
         <>
           <Box
               className="swiper-button-prev-special-products"
               sx={{
                   position:"absolute",
                   top:"50%",
                   left:0,
                   zIndex:100,
                   transform:"translate(-50%,-50%)",
                   backgroundColor: theme.palette.primary.main,
                   color: "white",
                   width: "30px",
                   height: "30px",
                   display: "flex",
                   p:0,
                   boxShadow:1,
                   borderRadius:1,
                   alignItems: "center",
                   justifyContent: "center",
                   cursor: "pointer",
               }}
           >
               <KeyboardArrowLeftIcon/>
           </Box>
       
           <Box
               className="swiper-button-next-special-products"
               sx={{
                   position: "absolute",
                   top: "50%",
                   right: 0,
                   p:0,
                   boxShadow:1,
                   borderRadius:1,
                   zIndex: 1500,
                   transform: "translate(50%,-50%)",
                   backgroundColor: theme.palette.primary.main,
                   color: "white",
                   width: "30px",
                   height: "30px",
                   display: "flex",
                   alignItems: "center",
                   justifyContent: "center",
                   cursor: "pointer",
               }}
           >
               <KeyboardArrowRightIcon/>
           </Box>
         </>
        )}
      </Box>
    </Container>
  );
}
